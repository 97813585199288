import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import TabsPage from './pages/Tabs';
import TabsPageDetail from './pages/TabsPageDetails';
import CreateNewTabsPage from './pages/CreateNewTabsPage';
import Countries from './pages/Countries';
import Journies from './pages/Journies';
import CreateNewCountryPage from './pages/CreateNewCountryPage';
import CreateNewJourneyPage from './pages/CreateNewJourneyPage';
import Language from './pages/Language';
import CreateNewLanguagePage from './pages/CreateNewLanguage';
import CreateTabsContent from './pages/CreateTabsContent';
import EditTabsContent from './pages/EditTabsContent';
import EditTabsContentContent from './contentpages/EditTabsContent';
import InfoPage from './pages/InfoPage';
import DashboardAppContentPage from './contentpages/DashboardAppPage';
import ContentDashboardLayout from './layouts/dashboard/ContentDashboardLayout';
import TabsContentPage from './contentpages/Tabs';
import ContentTabPageDetails from './contentpages/TabsPageDetails';
import ContentCreateTabsContent from './contentpages/CreateTabsContent';
import ContentCreatorCountry from './pages/ContentCreatorCountry';
import LanguageWiseTabs from './pages/LanguageWiseTabs';
import FaqsPage from './pages/FaqsPage';
import CreatorCountries from './contentpages/CreatorCountries';
import PushNotificationsPage from './pages/PushNotificationsPage';
import LandingPage from './userPages/LandingPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    // {

    //   path: '/',
    //   element:<LandingPage/>
    // },

    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'tabs', element: <TabsPage /> },
        { path: 'faqs', element: <FaqsPage /> },
        { path: 'countries', element: <Countries /> },
        { path: 'journies', element: <Journies /> },
        { path: 'tabs/:id', element: <TabsPageDetail /> },
        { path: 'language-wise-tabs/:id', element: <LanguageWiseTabs /> },
        { path: 'newtabs', element: <CreateNewTabsPage /> },
        { path: 'newcountries', element: <CreateNewCountryPage /> },
        { path: 'newjournies', element: <CreateNewJourneyPage /> },
        { path: 'language', element: <Language /> },
        { path: 'newlanguage', element: <CreateNewLanguagePage /> },
        { path: 'push-notifications', element: <PushNotificationsPage /> },
        { path: 'content/:tabId', element: <CreateTabsContent /> },
        { path: 'content/edit/:contentId', element: <EditTabsContent /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'info', element: <InfoPage /> },
        { path: 'content-creator-country/:userId', element: <ContentCreatorCountry /> },
      ],
    },

    {
      path: '/content-dashboard',
      element: <ContentDashboardLayout />,
      children: [
        { element: <Navigate to="/content-dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppContentPage /> },
        { path: 'tabs', element: <TabsContentPage /> },
        { path: 'countries', element: <CreatorCountries /> },
        { path: 'tabs/:id', element: <ContentTabPageDetails /> },
        { path: 'content/:tabId', element: <ContentCreateTabsContent /> },
        { path: 'content/edit/:contentId', element: <EditTabsContentContent /> },
        // { path: 'user', element: <UserPage /> },
        // { path: 'tabs', element: <TabsPage /> },
        // { path: 'countries', element: <Countries /> },
        // { path: 'journies', element: <Journies /> },
        // { path: 'tabs/:id', element: <TabsPageDetail /> },
        // { path: 'newtabs', element: <CreateNewTabsPage /> },
        // { path: 'newcountries', element: <CreateNewCountryPage /> },
        // { path: 'newjournies', element: <CreateNewJourneyPage /> },
        // { path: 'language', element: <Language /> },
        // { path: 'newlanguage', element: <CreateNewLanguagePage /> },
        // { path: 'content/:tabId', element: <CreateTabsContent /> },
        // { path: 'content/edit/:contentId', element: <EditTabsContent /> },
        // { path: 'blog', element: <BlogPage /> },
        // { path: 'info', element: <InfoPage /> },
      ],
    },

    {
      path: 'login',
      element: <LoginPage />,
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
