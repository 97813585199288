import {configureStore} from "@reduxjs/toolkit"
import tabSlice from "./reducers/tabReducer"
import snackBarSlice from "./reducers/snackBarReducer"

const store = configureStore({
    // Add your reducer(s) here
    reducer: {
        snackBarReducer:snackBarSlice,
        tabReducer:tabSlice
      // reducers go here
    },
  });
  
  export default store;