import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { TextField, FormControl, InputLabel, MenuItem, Select, Button, CircularProgress } from '@mui/material';
import { setMessageError, setMessageHide, setMessageShow } from '../state/reducers/snackBarReducer';
import TextEditor from '../components/editor/TextEditor';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';

function EditTabsContentContent() {
  const dispatch = useDispatch();
  const [journies, setJournies] = useState([]);
  const [language, setLanguage] = useState([]);
  const [jour, setJour] = useState('');
  const [body, setBody] = useState('');
  // const [jour, setJour] = useState('');
  const [originId, setOriginId] = useState('');
  const [loading, setLoading] = useState(false);
  const token = useGetToken();
  const { contentId } = useParams();
  const [lang, setLang] = useState('');
  const [tabId, setTabId] = useState('');
  const [title, setTitle] = useState('');
  const [youtubeVideoLink, setYoutubeVideoLink] = useState('');
  const [image, setImage] = useState('');
  const [backImage, setBackImage] = useState(null);

  useEffect(() => {
    if (token) {
      ax.get(`/admin/content/content/${contentId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          const data = response.data;
          setJour(data.content.journey._id);
          setLang(data.content.language._id);
          setBody(data.content.data);
          setTabId(data.content.tab);
          setTitle(data.content.title);
          setYoutubeVideoLink(data.content.youtube_video_link);
          setImage(`${BASE_URL}/${data.content.background_image}`);
          // // console.log(response.data);
        })
        .catch((error) => {
          // // console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      ax.get('/admin/journey', {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          setJournies(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token && jour !== '') {
      ax.get(`/admin/language/${originId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // // console.log(response.data);
          setLanguage(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token, jour]);

  const navigate = useNavigate();
  // const

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    if (backImage !== null) {
      formData.append('image', backImage);
    }

    formData.append('journey', jour);
    formData.append('language', lang);
    formData.append('data', body.toString());
    formData.append('title', title);
    formData.append('youtube_video_link', youtubeVideoLink);

    ax.post(`/admin/content/content/${contentId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        setLoading(false);
        dispatch(setMessageShow(response.data.message));
        navigate(`/dashboard/tabs/${tabId}`, { replace: true });
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      })
      .catch((error) => {
        // // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  const handleChangeJourney = (e) => {
    setJour(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Tabs content : Bideshonline </title>
      </Helmet>

      <h1>Create Content</h1>
      <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TextField value={title} onChange={(e) => setTitle(e.target.value)} label="Title" required />
        <FormControl required style={{ marginTop: 15 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Journey</InputLabel>
          <Select
            value={jour}
            onChange={handleChangeJourney}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Journey"
            required
          >
            {journies.map((journey) => (
              <MenuItem onClick={() => setOriginId(journey.origin._id)} key={journey._id} value={journey._id}>
                {journey.origin.name} - {journey.destination.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl required style={{ marginTop: 15 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            value={lang}
            onChange={(e) => setLang(e.target.value)}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Language"
            required
          >
            {language.map((language) => (
              <MenuItem key={language._id} value={language._id}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          style={{ marginTop: 20 }}
          value={youtubeVideoLink}
          onChange={(e) => setYoutubeVideoLink(e.target.value)}
          label="Youtube Video"
        />
        <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img style={{ width: '50%' }} src={image} alt="" />
        </div>

        <input
          onChange={(e) => setBackImage(e.target.files[0])}
          placeholder="image"
          type="file"
          style={{ width: '100%', border: '1px solid black', borderRadius: 5, padding: 20, marginTop: 15 }}
          required
        />

        <TextEditor setBody={setBody} body={body} />

        {loading ? (
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            <CircularProgress color="inherit" size={24} />
          </Button>
        ) : (
          <Button onClick={handleSubmit} type="submit" style={{ marginTop: 20 }} variant="contained" color="primary">
            Submit
          </Button>
        )}
      </form>
    </>
  );
}

export default EditTabsContentContent;
