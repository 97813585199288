import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextField, CircularProgress, Button, Input } from '@mui/material';
import { setMessageHide, setMessageShow } from  '../state/reducers/snackBarReducer';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';

// ----------------------------------------------------------------------

// const useStyles = makeStyles((theme) => ({
//   form: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     marginTop: theme.spacing(4),
//     height:"100%"
//   },
//   textField: {
//     margin: theme.spacing(1),
//     width: '300px',
//   },
//   button: {
//     margin: theme.spacing(2),
//     width: '150px',
//   },
// }));

export default function CreateNewTabsPage() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useGetToken();

  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('title', title);
    bodyFormData.append('tab-image', image);

    ax.post('/admin/tabs', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        dispatch(setMessageShow(response.data.message));
        navigate("/dashboard/tabs",{replace:true});
        setTimeout(() => {
        dispatch(setMessageHide());
        }, 3000);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title> New Tabs : Bideshonline </title>
      </Helmet>

      <h1>Create New Tabs</h1>
      <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TextField
          style={{ marginTop: 20 }}
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          accept="image/*"
          required
          type="file"
          style={{ marginTop: 10, border: '1px solid black', padding: 15, borderRadius: 5 }}
          onChange={(e) => setImage(e.target.files[0])}
        />
        {loading ? (
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            <CircularProgress size={25} color="inherit" />
          </Button>
        ) : (
          <Button type="submit" style={{ marginTop: 20 }} variant="contained" color="primary">
            Submit
          </Button>
        )}
      </form>
    </>
  );
}
