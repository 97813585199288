// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Tabs',
    path: '/dashboard/tabs',
    icon: icon('ic_cart'),
  },
  {
    title: 'Countries',
    path: '/dashboard/countries',
    icon: icon('ic_cart'),
  },

 
  {
    title: 'Journies',
    path: '/dashboard/journies',
    icon: icon('ic_cart'),
  },

  {
    title: 'Faqs',
    path: '/dashboard/faqs',
    icon: icon('ic_cart'),
  },
  {
    title: 'Language',
    path: '/dashboard/language',
    icon: icon('ic_blog'),
  },
  {
    title: 'info',
    path: '/dashboard/info',
    icon: icon('ic_blog'),
  },
  {
    title: 'pushnotifications',
    path: '/dashboard/push-notifications',
    icon: icon('ic_blog'),
  },


];

export default navConfig;
