import * as React from 'react';
import { useDispatch } from 'react-redux';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import { Typography, Button, Box, Modal, TextField, CircularProgress } from '@mui/material';
import ax, { BASE_URL } from '../../Axios';
import useGetToken from '../../utils/logic/getToken';
import { setMessageError, setMessageHide, setMessageShow } from '../../state/reducers/snackBarReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CountryComponent = ({ country }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openD, setOpenD] = React.useState(false);
  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(country.name);
  const [image, setImage] = React.useState(null);
  const [countryImage, setCountryImage] = React.useState(country.image);
  const token = useGetToken();

  const handleEditing = () => {
    setLoading(true);
    // if (image === null) {
    const formData = new FormData();
    formData.append('name', name);
    if (image !== null) {
      formData.append('image', image);
    }
    ax.post(`/admin/journey/change_country/${country._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        setLoading(false);
        handleClose();
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
        // // console.log(response.data.path);
        // // console.log(response.data.path===undefined);
        if (response.data.path !== undefined && response.data.path !== null) {
          setCountryImage(response.data.path);
        }
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
    // }
    // else{

    // }
  };

  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const handleDelete = () => {
    setDeleteLoading(true);
    ax.delete(`/admin/language/${country._id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setDeleteLoading(false);
        dispatch(setMessageShow(response.data.message));
        handleCloseD();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })

      .catch((error) => {
        // console.log(error);
        setDeleteLoading(false);
        dispatch(setMessageError(error.response.data.data));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  return (
    <TableRow key={country._id}>
      <TableCell align="left" component="th" scope="row">
        {name}
      </TableCell>

      <TableCell align="right">
        <img width={30} alt="check" src={`${BASE_URL}/${countryImage}`} />
      </TableCell>

      <TableCell align="right">{new Date(country.createdAt).toDateString()}</TableCell>

      <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
        <Button onClick={handleOpen} variant="contained">
          Edit
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={name}
              style={{ marginBottom: 10, width: '100%' }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
            />

            <Typography style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <input
                onChange={(e) => setImage(e.target.files[0])}
                style={{ border: '1px solid grey', padding: 15, borderRadius: 5, width: '70%' }}
                type="file"
              />
              <img style={{ width: '20%' }} alt="check" src={`${BASE_URL}/${country.image}`} />
            </Typography>
            {loading ? (
              <Button style={{ width: '100%', marginTop: 10 }} variant="contained" disableElevation>
                <CircularProgress size={20} color="inherit" />
              </Button>
            ) : (
              <Button
                onClick={handleEditing}
                style={{ width: '100%', marginTop: 10 }}
                variant="contained"
                disableElevation
              >
                Submit
              </Button>
            )}
          </Box>
        </Modal>
      </TableCell>

      <TableCell align="right">
        <Button onClick={handleOpenD} variant="contained" color="error" disableElevation>
          Delete
        </Button>

        <Modal
          open={openD}
          onClose={handleCloseD}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>
              <h2>Are you sure you want to delete {name} ?</h2>
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
              {deleteLoading ? (
                <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                  <CircularProgress color="inherit" size={20} />
                </Button>
              ) : (
                <Button
                  onClick={handleDelete}
                  color="error"
                  style={{ marginRight: 10 }}
                  variant="contained"
                  disableElevation
                >
                  Yes
                </Button>
              )}
              <Button onClick={handleCloseD} color="inherit" variant="contained" disableElevation>
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </TableCell>
    </TableRow>
  );
};

export default CountryComponent;
