import { createSlice } from '@reduxjs/toolkit';

const data = {
  loading: false,
  tabs: [],
  cacheTab: [],
  error: null,
  emptyList: null,
};

const tabSlice = createSlice({
  name: 'tabs',
  initialState: data,
  reducers: {
    startTabsFetch: (state) => {
      state.loading = true;
    },
    startTabsFetchSuccess: (state, action) => {
      state.loading = false;
      state.tabs = action.payload;
      state.cacheTab = action.payload;
    },
    startTabsFetchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    searchTabs: (state, action) => {
      state.tabs = state.cacheTab;
      state.tabs = state.tabs.filter((tab) => tab.title.toLowerCase().includes(action.payload.toLowerCase()));
      if (state.tabs.length === 0) {
        state.emptyList = 'Sorry ! Not found.';
      } else {
        state.emptyList = null;
      }
      // state.tabs
    },
    changeActiveStatus: (state, action) => {
      state.tabs = state.tabs.map((tab) => {
        if (tab._id === action.payload) {
          return { ...tab, active: !tab.active };
        }
        return tab;
      });

      state.cacheTab = state.cacheTab.map((tab) => {
        if (tab._id === action.payload) {
          return { ...tab, active: !tab.active };
        }
        return tab;
      });
    },
  },
});

export const { startTabsFetch, startTabsFetchSuccess, startTabsFetchError, searchTabs,changeActiveStatus } = tabSlice.actions;
export default tabSlice.reducer;
