import React, { createRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import useGetToken from '../../utils/logic/getToken';
import ax from '../../Axios';
import { setMessageError, setMessageHide, setMessageShow } from '../../state/reducers/snackBarReducer';
// import Iconify from '../components/iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function JourneyComponent({ journey }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openD, setOpenD] = React.useState(false);
  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);

  const [originName, setOriginName] = useState(journey?.origin?.name);
  const [destinationName, setDestinationName] = useState(journey?.destination?.name);

  const ref = createRef();

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const [origin, setOrigin] = React.useState(journey?.origin?._id);
  const [destination, setDestination] = React.useState(journey?.destination?._id);
  const token = useGetToken();

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    ax.get('/country')
      .then((response) => {
        // // console.log(response.data);
        setCountries(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleEditing = () => {};

  const handleChangeOrigin = (e) => {
    if (e.target.value === destination) {
      dispatch(setMessageError('Destination and Origin cannot be same.'));
      setTimeout(() => {
        dispatch(setMessageHide());
      }, 3000);
    } else {
      setOrigin(e.target.value);
    }
  };

  const handleChangeDestination = (e) => {
    if (e.target.value === origin) {
      dispatch(setMessageError('Destination and Origin cannot be same.'));
      setTimeout(() => {
        dispatch(setMessageHide());
      }, 3000);
    } else {
      setDestination(e.target.value);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    ax.post(
      `/admin/journey/${journey._id}`,
      {
        origin,
        destination,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        setLoading(false);
        handleClose();
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
        setOriginName(response.data.origin);
        setDestinationName(response.data.destination);
        // // console.log(response.data.path);
        // // console.log(response.data.path===undefined);
        //   if (response.data.path !== undefined && response.data.path !== null) {
        //     setCountryImage(response.data.path);
        //   }
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    ax.delete(`/admin/journey/${journey._id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setLoadingDelete(false);
        dispatch(setMessageShow(response.data.message));
        handleCloseD();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })

      .catch((error) => {
        // console.log(error);
        setLoadingDelete(false);
        dispatch(setMessageError(error.response.data.data));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  return (
    <TableRow>
      <TableCell align="left" component="th" scope="row">
        {originName}
      </TableCell>
      <TableCell align="center">{destinationName}</TableCell>
      {/* <TableCell align="center">{row.image}</TableCell> */}
      <TableCell align="right">{new Date(journey.createdAt).toDateString()}</TableCell>
      <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
        <Button onClick={handleOpen} variant="contained">
          Edit
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Origin</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={origin}
                label="Origin"
                onChange={handleChangeOrigin}
              >
                {countries?.map((country) => (
                  <MenuItem key={`o_${country._id}`} value={country._id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl ref={ref} style={{ marginTop: 20 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Destination</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={destination}
                label="Destination"
                onChange={handleChangeDestination}
              >
                {countries?.map((country) => (
                  <MenuItem key={country._id} value={country._id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {loading ? (
              <Button style={{ width: '100%', marginTop: 20 }} variant="contained" disableElevation>
                <CircularProgress size={20} color="inherit" />
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                style={{ width: '100%', marginTop: 20 }}
                variant="contained"
                disableElevation
              >
                Submit
              </Button>
            )}
          </Box>
        </Modal>
      </TableCell>

      <TableCell align="right">
        <Button onClick={handleOpenD} variant="contained" color="error" disableElevation>
          Delete
        </Button>

        <Modal
          open={openD}
          onClose={handleCloseD}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>
              <h2>Are you sure you want to delete this?</h2>
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
            {loadingDelete ? (
                <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                  <CircularProgress color="inherit" size={20} />
                </Button>
              ) : (
                <Button
                  onClick={handleDelete}
                  color="error"
                  style={{ marginRight: 10 }}
                  variant="contained"
                  disableElevation
                >
                  Yes
                </Button>
              )}
              <Button color="inherit" variant="contained" disableElevation>
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </TableCell>
    </TableRow>
  );
}

export default JourneyComponent;
