import * as React from 'react';
import Table from '@mui/material/Table';
import { useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button, Box, Modal, TextField } from '@mui/material';
import Iconify from '../components/iconify/Iconify';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';
import CountryComponent from '../components/country/CountryComponent';

export default function Countries() {
  const token = useGetToken();

  const [countries, setCountries] = React.useState([]);
  const [countriesCache, setCountriesCache] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setLoading(true);
      ax.get('/country', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          setCountries(response.data);
          setCountriesCache(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token]);

  const navigate = useNavigate();

  const handleSearchTab = (e) => {
    if (e.target.value === '') {
      setCountries(countriesCache);
    } else {
      const newCountries = countries.filter((country) =>
        country.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setCountries(newCountries);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard : Countries </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography
              style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              variant="h4"
              gutterBottom
            >
              Country
              <TextField onChange={handleSearchTab} placeholder="Search..." variant="outlined" label="Search" />
            </Typography>
            <Button
              onClick={() => navigate('/dashboard/newcountries')}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Country
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {countries?.map((country) => (
                  <CountryComponent key={country._id} country={country} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
