import * as React from 'react';
import Table from '@mui/material/Table';
import { useNavigate, useParams } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button, Modal, Box } from '@mui/material';
import Iconify from '../components/iconify/Iconify';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TabPageDetails() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const token = useGetToken();
  const { id } = useParams();

  const [contents, setContents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (token && id) {
      setLoading(true);
      ax.get(`/content/content/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setContents(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token, id]);

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Dashboard : Content </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Content
            </Typography>
            <Button
              onClick={() => navigate(`/content-dashboard/content/${id}`, { replace: true })}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Content
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Origin</TableCell>
                  <TableCell align="right">Destination</TableCell>
                  <TableCell align="right">Language</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contents?.map((content) => (
                  <TableRow key={content._id}>
                    <TableCell align="left" component="th" scope="row">
                      {content?.journey?.origin?.name}
                    </TableCell>

                    <TableCell align="right" component="th" scope="row">
                      {content?.journey?.destination?.name}
                    </TableCell>

                    <TableCell align="right" component="th" scope="row">
                      {content?.language?.name}
                    </TableCell>

                    <TableCell align="right" component="th" scope="row">
                      {content?.title?.length> 10 ? `${content?.title.slice(0,10)}...` : content?.title}
                    </TableCell>


                    <TableCell align="right">{new Date(content.createdAt).toDateString()}</TableCell>

                    <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
                      <Button onClick={() => navigate(`/content-dashboard/content/edit/${content._id}`)} variant="contained">
                        Edit
                      </Button>
                    </TableCell>

                    <TableCell align="right">
                      <Button onClick={handleOpen} variant="contained" color="error" disableElevation>
                        Delete
                      </Button>

                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography>
                            <h2>Are you sure you want to delete this ?</h2>
                          </Typography>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
                            <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                              Yes
                            </Button>
                            <Button color="inherit" variant="contained" disableElevation>
                              Cancel
                            </Button>
                          </div>
                        </Box>
                      </Modal>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
