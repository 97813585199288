import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import useGetToken from '../../utils/logic/getToken';
import ax from '../../Axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditAdminInfo() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState({ firstName: '', lastName: '', email: '' });

  const { firstName, lastName, email } = data;

  const token = useGetToken();

  React.useEffect(() => {
    if (token) {
      ax.get('/user/adminInfo', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          console.log(response.data);
          setData({
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            email: response.data.email,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const handleUpdateAdmin=()=>{
      setLoading(true);
      ax.post(
        '/user/editadminInfo',
        {
          first_name: firstName,
          last_name: lastName,
          email,
        },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      )
        .then((response) => {
          console.log(response.data);
          setLoading(false);
          localStorage.setItem("name",`${response.data.first_name} ${response.data.last_name}`)
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }



  return (
    <div>
      <Button variant="contained" color="success" style={{ color: 'white' }} disableElevation onClick={handleOpen}>
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            style={{ width: '100%', marginTop: 10 }}
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => setData({ ...data, firstName: e.target.value })}
          />
          <TextField
            style={{ width: '100%', marginTop: 10 }}
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => setData({ ...data, lastName: e.target.value })}
          />
          <TextField
            value={email}
            style={{ width: '100%', marginTop: 10 }}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          {loading ? (
            <Button style={{ width: '100%', height: 40, marginTop: 10 }} variant="contained" disableElevation>
              <CircularProgress size={24} color="inherit" />
            </Button>
          ) : (
            <Button onClick={handleUpdateAdmin} style={{ width: '100%', height: 40, marginTop: 10 }} variant="contained" disableElevation>
              Submit
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}
