import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';

// ----------------------------------------------------------------------

export default function DashboardAppContentPage() {
  const theme = useTheme();
  const token = useGetToken();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (token) {
      ax.get('/admin/stats', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title> Bideshonline </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

      </Container>
    </>
  );
}
