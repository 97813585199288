import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button, TextField } from '@mui/material';
import useGetToken from '../utils/logic/getToken';
import ax from '../Axios';
import Iconify from '../components/iconify/Iconify';
import JourneyComponent from '../components/journey/JourneyComponent';

export default function Journies() {
  const navigate = useNavigate();

  const token = useGetToken();

  const [journies, setJournies] = React.useState([]);
  const [journiesCache, setJourniesCache] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setLoading(true);
      ax.get('/admin/journey', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setJournies(response.data);
          setJourniesCache(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // // console.log(error);
          setLoading(false);
        });
    }
  }, [token]);

  const handleSearchTabOrigin = (e) => {
    if (e.target.value === '') {
      setJournies(journiesCache);
    } else {
      const newJourney = journies.filter(
        (journey) =>
          journey.origin.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setJournies(newJourney);
    }
  };

  const handleSearchTabDestination = (e) => {
    if (e.target.value === '') {
      setJournies(journiesCache);
    } else {
      const newJourney = journies.filter(
        (journey) =>
          journey.destination.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setJournies(newJourney);
    }
  };


  return (
    <>
      <Helmet>
        <title> Dashboard : Journies </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography
              style={{ width: '80%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
              variant="h4"
              gutterBottom
            >
              Journey
              <TextField style={{marginLeft:20}} onChange={handleSearchTabOrigin} placeholder="Search..." variant="outlined" label="Origin" />
              <TextField style={{marginLeft:20}} onChange={handleSearchTabDestination} placeholder="Search..." variant="outlined" label="Destination" />
            </Typography>
            <Button
              onClick={() => navigate('/dashboard/newjournies')}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Journey
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Origin</TableCell>
                  <TableCell align="center">Destination</TableCell>
                  {/* <TableCell align='center'>Image</TableCell> */}
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {journies.map((journey) => (
                  <JourneyComponent key={`j_${journey._id}`} journey={journey} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
