import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';
import { setMessageError, setMessageHide } from '../../state/reducers/snackBarReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function EditLanugageNameModel({ languages, lang, title,id }) {
  const [openE, setOpenE] = React.useState(false);
  const handleOpenE = () => setOpenE(true);
  const handleCloseE = () => setOpenE(false);
  const token = useGetToken();

  const [languageWiseTitle, setTitle] = useState(title);
  const [language, setLanguage] = useState(lang);
  const dispatch=useDispatch();

  const handleEdit = (e) => {
    e.preventDefault();
    ax.put(
      `/admin/languagewisetab/${id}`,
      {
        language_wise_title: languageWiseTitle,
        language,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        // console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };
  return (
    <>
      <Button onClick={handleOpenE} variant="contained">
        Edit
      </Button>

      <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleEdit}>
            <TextField
              style={{ width: '100%', marginBottom: 20 }}
              name="name"
              value={languageWiseTitle}
              onChange={(e) => setTitle(e.target.value)}
              //   onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
              placeholder="Name"
              variant="outlined"
              label="Name"
              required
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Role"
                required
              >
                {/* {userType} */}
                {languages?.map((lang) => (
                  <MenuItem value={lang._id}>{lang?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" style={{ width: '100%', marginTop: 20 }} variant="contained">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default EditLanugageNameModel;
