import React, { createRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import useGetToken from '../../utils/logic/getToken';
import ax from '../../Axios';
import { setMessageError, setMessageHide, setMessageShow } from '../../state/reducers/snackBarReducer';
// import Iconify from '../components/iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function FaqComponent({ faq,journies }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openD, setOpenD] = React.useState(false);
  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const [question, setQuestion] = useState(faq.question);
  const [answer, setAnswer] = useState(faq.answer);
  const [journey, setJourney] = React.useState('');

  const token = useGetToken();

  const handleSubmit = () => {
    setLoading(true);
    ax.put(
      `/faqs/${faq._id}`,
      {
        question,
        answer,
        journey
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        setLoading(false);
        handleClose();
        window.location.reload();

        // dispatch(setMessageShow(response.data.message));
        // setTimeout(() => {
        //   dispatch(setMessageHide());
        // }, 4000);

        // // console.log(response.data.path);
        // // console.log(response.data.path===undefined);
        //   if (response.data.path !== undefined && response.data.path !== null) {
        //     setCountryImage(response.data.path);
        //   }
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    ax.delete(`/faqs/${faq._id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setLoadingDelete(false);
        handleCloseD();
        window.location.reload();
        // dispatch(setMessageShow(response.data.message));
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })

      .catch((error) => {
        // console.log(error);
        setLoadingDelete(false);
        dispatch(setMessageError(error.response.data.data));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  return (
    <TableRow>
      <TableCell align="left" component="th" scope="row">
        {faq.question.length > 50 ? `${faq.question.slice(0, 50)}...` : faq.question}
      </TableCell>
      <TableCell align="center">{faq.answer.length > 80 ? `${faq.answer.slice(0, 80)}...` : faq.answer}</TableCell>
      <TableCell align="center">
        {faq?.journey?.origin?.name} {'  -  '} {faq?.journey?.destination?.name}
      </TableCell>
      {/* <TableCell align="center">{row.image}</TableCell> */}
      <TableCell align="right">{new Date(faq.createdAt).toDateString()}</TableCell>
      <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
        <Button onClick={handleOpen} variant="contained">
          Edit
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              label="Question"
              style={{ width: '100%', marginBottom: 20 }}
              placeholder="Question"
            />

            <FormControl required style={{ marginBottom: 20 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Journey</InputLabel>
                <Select
                  // onMouseEnter={handlefetchJourney}
                  value={journey}
                  onChange={(e) => {
                    setJourney(e.target.value);
                    // handleJourneyDropdown(e.target.value);
                    // console.log(e.target.value);
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  required
                >
                  {journies?.map((jor) => (
                    <MenuItem value={jor._id}>
                      {jor?.origin?.name} - {jor?.destination?.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>


            <TextareaAutosize
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              label="Answer"
              style={{ width: '100%', marginBottom: 20, height: 300 }}
              placeholder="Answer"
            />
            {loading ? (
              <Button style={{ width: '100%', marginTop: 20 }} variant="contained" disableElevation>
                <CircularProgress size={20} color="inherit" />
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                style={{ width: '100%', marginTop: 20 }}
                variant="contained"
                disableElevation
              >
                Submit
              </Button>
            )}
          </Box>
        </Modal>
      </TableCell>

      <TableCell align="right">
        <Button onClick={handleOpenD} variant="contained" color="error" disableElevation>
          Delete
        </Button>

        <Modal
          open={openD}
          onClose={handleCloseD}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>
              <h2>Are you sure you want to delete this?</h2>
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
              {loadingDelete ? (
                <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                  <CircularProgress color="inherit" size={20} />
                </Button>
              ) : (
                <Button
                  onClick={handleDelete}
                  color="error"
                  style={{ marginRight: 10 }}
                  variant="contained"
                  disableElevation
                >
                  Yes
                </Button>
              )}
              <Button color="inherit" variant="contained" disableElevation>
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </TableCell>
    </TableRow>
  );
}

export default FaqComponent;
