import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Typography,
  Stack,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import useGetToken from '../utils/logic/getToken';
import ax from '../Axios';
import Iconify from '../components/iconify/Iconify';
import JourneyComponent from '../components/journey/JourneyComponent';
import FaqComponent from '../components/faq/FaqComponent';
import NewFaqModal from '../components/faq/NewFaqModel';

export default function FaqsPage() {
  const navigate = useNavigate();

  const token = useGetToken();

  const [journies, setJournies] = React.useState([]);
  const [journey, setJourney] = React.useState('');
  const [journiesCache, setJourniesCache] = React.useState([]);

  const [faqs, setFaqs] = React.useState([]);
  const [faqsCache, setFaqsCahce] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setLoading(true);

      ax.get('/faqs', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          console.log(response.data);
          setFaqs(response.data);
          setFaqsCahce(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // // console.log(error);
          setLoading(false);
        });

      ax.get('/admin/journey', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          console.log(response.data);
          // setFaqs(response.data);
          // setFaqsCahce(response.data);
          setJournies(response.data);
          // setJourniesCache(response.data);
          // setLoading(false);
        })
        .catch((error) => {
          // // console.log(error);
          // setLoading(false);
        });
    }
  }, [token]);

  const handleSearchTab = (e) => {
    if (e.target.value === '') {
      setFaqs(faqsCache);
    } else {
      // setJournies([]);
      const newFaq = faqs.filter((faq) => faq.question.toLowerCase().includes(e.target.value.toLowerCase()));
      setFaqs(newFaq);
    }
  };

  const handleJourneyDropdown = (id) => {
    console.log(id);
    // setFaqs(faqsCache);
    const fqs = faqs.filter((faq) => (faq.journey === null ? false : faq.journey?._id === id));
    setFaqs(fqs);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard : Journies </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography
              style={{ width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              variant="h4"
              gutterBottom
            >
              Faqs
              <TextField
                style={{ marginLeft: 20 }}
                onChange={handleSearchTab}
                placeholder="Search..."
                variant="outlined"
                label="Search"
              />
              <FormControl required style={{ marginLeft: 20 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Journey</InputLabel>
                <Select
                  // onMouseEnter={handlefetchJourney}
                  value={journey}
                  onChange={(e) => {
                    setJourney(e.target.value);
                    handleJourneyDropdown(e.target.value);
                    // console.log(e.target.value);
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  required
                >
                  {journies?.map((jor) => (
                    <MenuItem value={jor._id}>
                      {jor?.origin?.name} - {jor?.destination?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Typography>

            <NewFaqModal />
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Question</TableCell>
                  <TableCell align="center">Answer</TableCell>
                  <TableCell align="center">Journey</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {faqs.map((faq) => (
                  <FaqComponent journies={journies} key={`j_${faq._id}`} faq={faq} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
