import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  Chip,
  TextField,
  CircularProgress,
  Breadcrumbs,
} from '@mui/material';
import useGetToken from '../../utils/logic/getToken';
import ax from '../../Axios';
import { setMessageShow, setMessageHide, setMessageError } from '../../state/reducers/snackBarReducer';
import Label from '../label/Label';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function UserListItemCompone({
  handleOpenMenu,
  selectedUser,
  _id,
  firstName,
  email,
  lastName,
  userType,
  verified,
  createdAt,
  handleContentCountry,
  handleClick,
  countries,
  origin,
}) {
  const [data, setData] = useState({
    first_name: firstName,
    last_name: lastName,
    type: userType,
    or: origin,
    em: email,
    dest: '',
    // origin:
  });
  const { first_name: fName, last_name: lName, type, or, em, dest } = data;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [destinations, setDestinations] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
    if (token) {
      ax.get(`/country/user-destination`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setDestinations(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const handleClose = () => setOpen(false);
  const token = useGetToken();
  const [loading, setLoading] = useState(false);
  const [activeInActiveLoading, setActiveInActiveLoading] = useState(false);
  const [ver, setVer] = useState(verified);

  const handleEditUser = () => {
    setLoading(true);
    ax.put(
      `/user/${_id}`,
      {
        first_name: fName,
        last_name: lName,
        type,
        origin: or,
        email: em,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        setLoading(false);
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          window.location.reload();
        }, 1000);

        //   setLanguage(response.data);
        //   setLanguageCache(response.data);
        //   setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        setMessageError(error.response.data.error);
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 2000);

        //   setLoading(false);
      });
  };

  const addToDestionas = (country) => {
    //  const new
    if (!destinations.some((dest) => dest.destination._id === country._id)) {
      setDestinations([...destinations, { destination: { name: country.name, _id: country._id }, _id: country._id }]); // Adding the new object only if its ID doesn't exist
    }
  };

  const handleDelete = (_id) => {
    const updatedItems = destinations.filter((dest) => dest.destination._id !== _id);
    setDestinations(updatedItems);
  };

  const handleLoadingActiveInActive = () => {
    setActiveInActiveLoading(true);
    ax.get(`/user/user-status/${_id}`, {
      headers: {
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        dispatch(setMessageShow(response.data.message));
        setVer(response.data.status);
        setActiveInActiveLoading(false);
        setTimeout(() => {
          // dispatch(setMessageHide());
          window.location.reload();
        }, 100);
        //   setLanguage(response.data);
        //   setLanguageCache(response.data);
        //   setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        setMessageError(error.response.data.error);
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 2000);

        setActiveInActiveLoading(false);
        //   setLoading(false);
      });
    // setTimeout(() => {
    //   setActiveInActiveLoading(false);
    // }, 3000);
  };

  return (
    <>
      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell padding="checkbox">
          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
        </TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={firstName} src="" />
            {userType === 'content' ? (
              <>
                <Typography variant="subtitle2" noWrap>
                  {firstName} {lastName}
                </Typography>
                <Chip onClick={() => handleContentCountry(_id)} color="primary" label="Assign" variant="outlined" />
              </>
            ) : (
              <Typography variant="subtitle2" noWrap>
                {firstName} {lastName}
              </Typography>
            )}
          </Stack>
        </TableCell>

        {/* <TableCell align="left">{company}</TableCell> */}

        <TableCell align="left">{userType === 'normal' ? 'Subscriber' : userType}</TableCell>

        <TableCell align="left">
          {/* <Typography> */}
          {ver ? (
            <>
              {activeInActiveLoading ? (
                <Button
                  // onClick={handleLoadingActiveInActive}
                  color="success"
                  style={{ color: 'white', fontWeight: 900, width: 80, borderRadius: 40 }}
                  variant="contained"
                  disableElevation
                >
                  <CircularProgress color="inherit" size={24} />
                </Button>
              ) : (
                <Button
                  onClick={handleLoadingActiveInActive}
                  color="success"
                  style={{ color: 'white', fontWeight: 900, width: 80, borderRadius: 40 }}
                  variant="contained"
                  disableElevation
                >
                  Active
                </Button>
              )}
            </>
          ) : (
            // <Chip color="error" style={{ color: 'white', fontWeight: 900, width: 80 }} label="Inactive" />
            <>
              {activeInActiveLoading ? (
                <Button
                  color="error"
                  style={{ color: 'white', fontWeight: 900, width: 80, borderRadius: 40 }}
                  variant="contained"
                  disableElevation
                >
                  <CircularProgress color="inherit" size={24} />
                </Button>
              ) : (
                <Button
                  onClick={handleLoadingActiveInActive}
                  color="error"
                  style={{ color: 'white', fontWeight: 900, width: 80, borderRadius: 40 }}
                  variant="contained"
                  disableElevation
                >
                  Inactive
                </Button>
              )}
            </>
          )}
          {/* </Typography> */}
        </TableCell>

        <TableCell align="left">{new Date(createdAt).toDateString()}</TableCell>

        <TableCell align="left">
          <Button variant="contained" onClick={handleOpen}>
            Edit
          </Button>
        </TableCell>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              style={{ width: '100%', marginBottom: 20 }}
              name="email"
              value={em}
              onChange={(e) => setData({ ...data, em: e.target.value })}
              placeholder="Email"
              variant="outlined"
              label="Email"
            />
            <TextField
              style={{ width: '100%', marginBottom: 20 }}
              name="first_name"
              value={fName}
              onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
              placeholder="Firstname"
              variant="outlined"
              label="First Name"
            />
            <TextField
              value={lName}
              name="last_name"
              onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
              style={{ width: '100%', marginBottom: 20 }}
              placeholder="Lastname"
              variant="outlined"
              label="Last Name"
            />
            <FormControl style={{ width: '100%', marginBottom: 20 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                value={type}
                onChange={(e) => setData({ ...data, type: e.target.value })}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Role"
              >
                {/* {userType} */}
                <MenuItem value="normal">Subscriber</MenuItem>
                <MenuItem value="content">Creator</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Origin</InputLabel>
              <Select
                value={or}
                onChange={(e) => setData({ ...data, or: e.target.value })}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Origin"
              >
                {/* {userType} */}
                {countries?.map((country) => (
                  <MenuItem value={country._id}>{country.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginTop: 20 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Destination</InputLabel>
              <Select
                value={dest}
                onChange={(e) => setData({ ...data, dest: e.target.value })}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Destination"
              >
                {/* {userType} */}
                {countries?.map((country) => (
                  <MenuItem onClick={() => addToDestionas(country)} value={country._id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <h4>Destinations</h4> */}
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridGap: 10,
                marginTop: 20,
              }}
            >
              {destinations.map((dest) => (
                <Chip
                  label={dest.destination.name}
                  onDelete={() => {
                    handleDelete(dest.destination._id);
                  }}
                />
              ))}
            </div>

            {loading ? (
              <Button style={{ width: '100%', marginTop: 20 }} variant="contained" disableElevation>
                <CircularProgress color="inherit" size={24} />
              </Button>
            ) : (
              <Button
                onClick={handleEditUser}
                style={{ width: '100%', marginTop: 20 }}
                variant="contained"
                disableElevation
              >
                Change
              </Button>
            )}
          </Box>
        </Modal>
      </TableRow>
      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}
    </>
  );
}

export default UserListItemCompone;
