import * as React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/system';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';
import { setMessageShow, setMessageHide, setMessageError } from '../../state/reducers/snackBarReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditTabsModal({ tab }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(tab.title);
  const [image, setImage] = React.useState(null);

  const token = useGetToken();

  const handleEdit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', name);
    if (image) {
      formData.append('tab-image', image);
    }
    ax.put(
      `/admin/tabs/${tab._id}`,
      formData,

      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          dispatch(setMessageHide());
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  return (
    <div>
      <EditIcon className="TabsCard_Top_Edit" onClick={handleOpen}>
        Open modal
      </EditIcon>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Edit this tab
          </Typography>

          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: '100%', marginTop: 10 }}
            type="contained"
            placeholder="Title..."
          />

          {/* <TextField style={{width:"100%",marginTop:10}} type='contained' placeholder='Title...' /> */}
          <input
            onChange={(e) => setImage(e.target.files[0])}
            style={{ marginTop: 10, border: '1px solid grey', width: '100%', borderRadius: 8, padding: 20 }}
            type="file"
          />

          <Stack direction="row" justifyContent="flex-end" spacing={2} style={{ marginTop: 20 }}>
            {loading ? (
              <Button style={{ color: 'white' }} variant="contained" color="success" disableElevation>
                <CircularProgress color="inherit" size={24} />
              </Button>
            ) : (
              <Button
                style={{ color: 'white' }}
                onClick={handleEdit}
                variant="contained"
                color="success"
                disableElevation
              >
                Edit
              </Button>
            )}
            <Button onClick={handleClose} variant="contained" disableElevation>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
