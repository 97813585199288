import * as React from 'react';
import Table from '@mui/material/Table';
import { useNavigate, useParams } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button, Modal, Box, CircularProgress, TextField } from '@mui/material';
import Iconify from '../components/iconify/Iconify';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';
import ContentListItem from './TabsPageDetailsComponents.js/ContentListItem';



export default function ContentTabPageDetails() {


  const token = useGetToken();
  const { id } = useParams();

  const [contents, setContents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [countriesCache, setCountriesCache] = React.useState([]);


  React.useEffect(() => {
    if (token && id) {
      setLoading(true);
      ax.get(`/admin/content/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // // console.log(response.data);
          setContents(response.data);
          setCountriesCache(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token, id]);

  const navigate = useNavigate();


  const originSearch = (e) => {
    if (e.target.value === '') {
      setContents(countriesCache);
    } else {
      const cons = contents.filter((content) =>
        content.journey.origin.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setContents(cons);
    }
  };

  const destinationSearch = (e) => {
    if (e.target.value === '') {
      setContents(countriesCache);
    } else {
      const cons = contents.filter((content) =>
        content.journey.destination.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setContents(cons);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard : Content </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              variant="h4"
              gutterBottom
            >
              Content
              <TextField
                onChange={originSearch}
                placeholder="Origin..."
                style={{ marginLeft: 10 }}
                variant="outlined"
                label="Origin"
              />
              <TextField
                placeholder="Destination..."
                onChange={destinationSearch}
                style={{ marginLeft: 10 }}
                variant="outlined"
                label="Destination"
              />
            </Typography>
            <Button
              onClick={() => navigate(`/dashboard/content/${id}`, { replace: true })}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Content
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Origin</TableCell>
                  <TableCell align="right">Destination</TableCell>
                  <TableCell align="right">Language</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Creator</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">Updated At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {contents?.map((content) => <ContentListItem content={content} navigate={navigate}/>)}

              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
