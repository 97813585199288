import * as React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField, FormControl, InputLabel, Select, CircularProgress } from '@mui/material';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';
import { setMessageError, setMessageHide, setMessageShow } from '../../state/reducers/snackBarReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewUserModel() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);
  const token = useGetToken();
  const dispatch = useDispatch();

  const [data, setData] = React.useState({
    fName: '',
    lName: '',
    email: '',
    password: '',
    type: '',
  });
  const { lName, fName, email, password, type } = data;

  const createUser = () => {
    setLoading(true);
    ax.post(
      '/user/by-admin',
      {
        first_name: fName,
        last_name: lName,
        email,
        password,
        type,
        deviceId:""
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        setLoading(false);
        // console.log(response.data);
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 2000);
      });
  };

  const handleChnage = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        {' '}
        + New User
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            name="fName"
            value={fName}
            onChange={handleChnage}
            type="text"
            placeholder="text"
            label="Firstname"
            style={{ width: '100%', marginBottom: 10 }}
          />
          <TextField
            name="lName"
            value={lName}
            onChange={handleChnage}
            type="text"
            placeholder="text"
            label="Lastname"
            style={{ width: '100%', marginBottom: 10 }}
          />
          <TextField
            name="email"
            value={email}
            onChange={handleChnage}
            type="email"
            placeholder="email"
            label="Email"
            style={{ width: '100%', marginBottom: 10 }}
          />
          <TextField
            name="password"
            value={password}
            onChange={handleChnage}
            type="password"
            placeholder="password"
            label="Password"
            style={{ width: '100%', marginBottom: 10 }}
          />
          <FormControl style={{ marginBottom: 20 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Type"
              onChange={(e)=>setData({...data,type:e.target.value})}
            >
              <MenuItem value="normal">Subscriber</MenuItem>
              <MenuItem value="content">Creator</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              {/* {countries?.map((country) => (
                  <MenuItem key={`o_${country._id}`} value={country._id}>
                    {country.name}
                  </MenuItem>

                ))} */}
            </Select>
          </FormControl>
          {loading ? (
            <Button variant="contained" style={{ width: '100%' }}>
              <CircularProgress color="inherit" size={24} />
            </Button>
          ) : (
            <Button onClick={createUser} variant="contained" style={{ width: '100%' }}>
              Create
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}
