import * as React from 'react';
import Table from '@mui/material/Table';
import { useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button,TextField } from '@mui/material';
import Iconify from '../components/iconify/Iconify';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';
import LanguageComponent from '../components/language/LanguageComponent';

export default function Language() {
  const token = useGetToken();

  const [language, setLanguage] = React.useState([]);
  const [languageCache, setLanguageCache] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setLoading(true);
      ax.get('/admin/language', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setLanguage(response.data);
          setLanguageCache(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token]);

  const navigate = useNavigate();

  const handleSearchTabByOrigin = (e) => {
    if (e.target.value === '') {
      setLanguage(languageCache);
    } else {
      const newLang = language.filter(
        (lan) =>
          lan.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          lan.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setLanguage(newLang);
    }
  };


  return (
    <>
      <Helmet>
        <title> Dashboard : Language </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
         
            <Typography
              style={{ width: '80%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
              variant="h4"
              gutterBottom
            >
             Language 
              <TextField style={{marginLeft:20}} onChange={handleSearchTabByOrigin} placeholder="Search By Origin..." variant="outlined" label="Origin" />
            </Typography>
            <Button
              onClick={() => navigate('/dashboard/newlanguage')}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Language
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">

              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="right">Country</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {language?.map((language) => (
                  <LanguageComponent key={language._id} language={language} />
                ))}
              </TableBody>

            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
