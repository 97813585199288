import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const useGetToken=()=>{
    const [tok,setToken]=useState(null)
    const navigate=useNavigate();
    useEffect(()=>{
        const tok=localStorage.getItem("token")

        // if(tok===null)
        // {
        //     navigate("/login",{replace:true})
        // }

        setToken(tok)
    },[])
    return tok
}

export default useGetToken