import { Helmet } from 'react-helmet-async';
import { filter, first } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Chip,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import useGetToken from '../utils/logic/getToken';
import ax from '../Axios';
import UserListItemCompone from '../components/user/UserListItemCompone';
import NewUserModel from '../components/user/NewUserModel';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'verified', label: 'v', alignRight: false },
  { id: 'date', label: 'Joined', alignRight: false },
  { id: 'edit', label: 'Edit', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [USERLIST, setUSERLIST] = useState([]);
  const [USERLISTCACHE, setUSERLISTCACHE] = useState([]);
  const [userTypeFront, setUserTypeFront] = useState('n');
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState([]);

  const token = useGetToken();
  // const params=URLSearchParams();

  useEffect(() => {
    // // console.log(params);
    if (token && userTypeFront) {
      ax.get(`/country`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // // console.log(response.data);
          // setUSERLIST(response.data);
          setCountries(response.data);
          // setUSERLISTCACHE(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    if (token && userTypeFront) {
      ax.get(`/user/${userTypeFront}`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setUSERLIST(response.data);
          setUSERLISTCACHE(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token, userTypeFront]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const filteredUsers = USERLIST;

  const isNotFound = !filteredUsers.length && !!filterName;

  const navigate = useNavigate();

  const handleContentCountry = (userId) => {
    // // console.log(userId);
    navigate(`/dashboard/content-creator-country/${userId}`);
  };

  const handleSearchUser = (e) => {
    if (e.target.value === '') {
      setUSERLIST(USERLISTCACHE);
      // setLanguage(languageCache);
    } else {
      const newUsers = USERLIST.filter(
        (user) =>
          user.first_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          user.last_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUSERLIST(newUsers);
    }
  };

  const handleDownloadCsv = () => {
    if (token) {
      ax.get(`/download-csv-of-user`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          const blob=response.data;
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
  
          // Optionally, clean up the URL object after the download
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title> User | Bideshonline </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
              <Button
                variant="outlined"
                onClick={handleDownloadCsv}
                color="primary"
                disableElevation
                style={{ marginLeft: 10 }}
              >
                Download Csv
              </Button>
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}

          <NewUserModel />
        </Stack>

        <Card>
          <Typography style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              style={{ margin: 20 }}
              onChange={handleSearchUser}
              placeholder="Search..."
              variant="outlined"
              label="Search"
            />
            <Typography style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
              {userTypeFront === 'n' ? (
                <Button variant="contained">Subscriber</Button>
              ) : (
                <Button onClick={() => setUserTypeFront('n')} variant="contained" color="inherit">
                 Subscriber 
                </Button>
              )}
              {userTypeFront === 'c' ? (
                <Button variant="contained">Creator</Button>
              ) : (
                <Button onClick={() => setUserTypeFront('c')} color="inherit" variant="contained">
                  Creator
                </Button>
              )}
              {userTypeFront === 'a' ? (
                <Button variant="contained">Admin</Button>
              ) : (
                <Button onClick={() => setUserTypeFront('a')} color="inherit" variant="contained">
                  Admin
                </Button>
              )}
            </Typography>
          </Typography>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <h1>Loading....</h1>
                ) : (
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const {
                        _id,
                        first_name: firstName,
                        email,
                        last_name: lastName,
                        userType,
                        verified,
                        createdAt,
                        origin,
                      } = row;

                      const selectedUser = selected.indexOf(_id) !== -1;

                      return (
                        <UserListItemCompone
                          selectedUser={selectedUser}
                          handleContentCountry={handleContentCountry}
                          handleClick={handleClick}
                          handleOpenMenu={handleOpenMenu}
                          key={_id}
                          _id={_id}
                          firstName={firstName}
                          lastName={lastName}
                          email={email}
                          userType={userType}
                          verified={verified}
                          createdAt={createdAt}
                          countries={countries}
                          origin={origin}
                        />
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}
    </>
  );
}
