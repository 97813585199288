import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import Iconify from '../iconify';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

export default function CreatePushNotificationModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const token = useGetToken();

  React.useEffect(() => {}, [token]);

  const handleSubmit = () => {
    setLoading(true);
    if (token) {
      ax.post(
        `/notification/push`,
        { title, body },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      )
        .then((response) => {
          // // console.log(response.data);
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          // // console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
        New Push Notification
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <span style={{ fontSize: 20, fontWeight: 900 }}>Create Push Notifications</span>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginTop: 30, width: '90%' }}
            id="outlined-basic"
            label="Title"
            variant="outlined"
          />
          {/* <TextField style={{ marginTop: 10, width: '90%' }} id="outlined-basic" label="Body" variant="outlined" /> */}
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            style={{ width: '90%', marginTop: 10, height: 300, borderRadius: 10, padding: 10, borderColor: 'grey' }}
            placeholder="Body"
          />
          {loading ? (
            <Button style={{ marginTop: 15, marginBottom: 30, width: '90%' }} variant="contained" disableElevation>
              <CircularProgress color="inherit" size={24} />
            </Button>
          ) : (
            <Button onClick={handleSubmit} style={{ marginTop: 15, marginBottom: 30, width: '90%' }} variant="contained" disableElevation>
              Submit
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}
