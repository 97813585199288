import { createSlice } from '@reduxjs/toolkit';

const data = {
  loading: false,
  message:"",
  error: null,
};

const snackBarSlice = createSlice({
  name: 'snacks',
  initialState: data,
  reducers: {
    setMessageShow:(state,action)=>
    {
       state.message=action.payload;
       state.loading=true; 
    },
    setMessageError:(state,action)=>{
      state.error=action.payload;
      state.loading=true;
    },
    setMessageHide:(state,action)=>
    {
       state.error=null;
       state.message="";
       state.loading=false; 
    },


    // startTabsFetch: (state) => {
    //   state.loading = true;
    // },
    // startTabsFetchSuccess: (state, action) => {
    //   state.loading = false;
    //   state.tabs = action.payload;
    // },
    // startTabsFetchError: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
  },
});

export const { setMessageShow, setMessageHide,setMessageError } = snackBarSlice.actions;
export default snackBarSlice.reducer;
