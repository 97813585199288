import { React, useState, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import '../../css/snackbar.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
//
// import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import useGetToken from '../../utils/logic/getToken';
import Header from './header';
import ax from '../../Axios';
import Nav from './nav';
import ContentNav from './contentnav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

const action = (
  <>
    <Button color="secondary" size="small" onClick={() => {}}>
      UNDO
    </Button>
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => {}}>
      {/* <CloseIcon fontSize="small" /> */}
    </IconButton>
  </>
);

export default function ContentDashboardLayout() {
  const { loading, message, error } = useSelector((state) => state.snackBarReducer);
  const [open, setOpen] = useState(false);
  const [openN, setOpenN] = useState(true);
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();

  const token = useGetToken();

  useEffect(() => {
    if (token) {
      ax.get('/user/user_type', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          setUserType(response.data.type.userType);

          if (response.data.type.userType !== 'content') {
            localStorage.removeItem('token');
            navigate('/login', { replace: true });
          }
        })
        .catch((error) => {
          // console.log(error);
          localStorage.removeItem('token');
          navigate('/login', { replace: true });
        });
    }
  }, [token]);

  return (
    <StyledRoot>
      {token === null || token === undefined || userType === null ? (
        <>
         
        </>
      ) : (
        <>
          {userType === 'admin' && <Navigate to="/dashboard" />}

          <Header onOpenNav={() => setOpen(true)} />
          <ContentNav openNav={open} onCloseNav={() => setOpen(false)} />

          <Main>
            <Outlet />
          </Main>

          {error === null ? (
            <Snackbar className="snacksuccess" open={loading} autoHideDuration={6000} message={message} />
          ) : (
            <Snackbar className="snackerror" open={loading} autoHideDuration={6000} message={error} />
          )}
        </>
      )}
    </StyledRoot>
  );
}
