import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import { Button, Modal, Box, Typography, FormControl, MenuItem, InputLabel, Select,CircularProgress, TextField } from '@mui/material';
import { setMessageHide, setMessageShow,setMessageError } from '../../state/reducers/snackBarReducer';
import useGetToken from '../../utils/logic/getToken';
import ax from '../../Axios';
// import Iconify from '../components/iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function LanguageComponent({ language }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openD, setOpenD] = React.useState(false);
  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [countries, setCountries] = useState([]);

  const [country, setCountry] = useState(language?.country?._id);
  const [languageName, setLanguageName] = useState(language?.name);
  const [countryName, setCountryName] = useState(language?.country?.name);

  const token = useGetToken();

  useEffect(() => {
    ax.get('/country')
      .then((response) => {
        // // console.log(response.data);
        setCountries(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleChangeLanguage = (e) => {
    setCountry(e.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    ax.post(
      `/admin/language/${language._id}`,
      {
        name: languageName,
        country,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        handleClose();
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
        setCountryName(response.data.country);
        
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    ax.delete(`/admin/language/${language._id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setLoadingDelete(false);
        dispatch(setMessageShow(response.data.message));
        handleCloseD();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })

      .catch((error) => {
        // console.log(error);
        setLoadingDelete(false);
        dispatch(setMessageError(error.response.data.data));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  return (
    <TableRow key={language._id}>
      <TableCell align="left" component="th" scope="row">
        {languageName}
      </TableCell>

      <TableCell align="right" component="th" scope="row">
        {countryName}
      </TableCell>

      <TableCell align="right">{new Date(language.createdAt).toDateString()}</TableCell>

      <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
        <Button onClick={handleOpen} variant="contained">
          Edit
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={languageName}
              style={{ marginBottom: 10, width: '100%' }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={(e) => setLanguageName(e.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Country"
                onChange={handleChangeLanguage}
              >
                {countries?.map((country) => (
                  <MenuItem key={`o_${country._id}`} value={country._id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {loading ? (
              <Button style={{ width: '100%', marginTop: 20 }} variant="contained" disableElevation>
                <CircularProgress size={20} color="inherit" />
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                style={{ width: '100%', marginTop: 20 }}
                variant="contained"
                disableElevation
              >
                Submit
              </Button>
            )}
          </Box>
        </Modal>
      </TableCell>

      <TableCell align="right">
        <Button onClick={handleOpenD} variant="contained" color="error" disableElevation>
          Delete
        </Button>

        <Modal
          open={openD}
          onClose={handleCloseD}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>
              <h2>Are you sure you want to delete {languageName}?</h2>
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
            {loadingDelete ? (
                <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                  <CircularProgress color="inherit" size={20} />
                </Button>
              ) : (
                <Button
                  onClick={handleDelete}
                  color="error"
                  style={{ marginRight: 10 }}
                  variant="contained"
                  disableElevation
                >
                  Yes
                </Button>
              )}
              <Button color="inherit" variant="contained" disableElevation>
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </TableCell>
    </TableRow>
  );
}

export default LanguageComponent;
