import * as React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';
import { setMessageError, setMessageHide, setMessageShow } from '../../state/reducers/snackBarReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewFaqModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [question, setQuestion] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [journey, setJourney] = React.useState('');
  const [journies, setJournies] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const token = useGetToken();
  const dispatch = useDispatch();


  const handleSubmit = () => {
    setLoading(true);
    ax.post(
      `/faqs/for-admin`,
      {
        question,
        answer,
        journey
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        setLoading(false);
        handleClose();
        window.location.reload();

        // dispatch(setMessageShow(response.data.message));
        // setTimeout(() => {
        //   dispatch(setMessageHide());
        // }, 4000);

        // // console.log(response.data.path);
        // // console.log(response.data.path===undefined);
        //   if (response.data.path !== undefined && response.data.path !== null) {
        //     setCountryImage(response.data.path);
        //   }
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
  };

  const handlefetchJourney=()=>{
    // console.log("clickkkeddd");
    if (token) {
      ax.get('/admin/journey', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setJournies(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        + New Faq
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl   required style={{ marginBottom: 20 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Journey</InputLabel>
            <Select
            onMouseEnter={handlefetchJourney}
              value={journey}
              onChange={(e) => setJourney(e.target.value)}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Role"
              required
            >
              {/* {userType} */}
              {journies?.map((jor) => (
                <MenuItem value={jor._id}>{jor?.origin?.name} - {jor?.destination?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            label="Question"
            style={{ width: '100%', marginBottom: 20 }}
            placeholder="Question"
            required
          />
          <TextareaAutosize
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            label="Answer"
            style={{ width: '100%', marginBottom: 20, height: 300 }}
            placeholder="Answer"
            required
          />

          {loading ? (
            <Button style={{ width: '100%', marginTop: 20 }} variant="contained" disableElevation>
              <CircularProgress size={20} color="inherit" />
            </Button>
          ) : (
            <Button
              onClick={handleSubmit}
              style={{ width: '100%', marginTop: 20 }}
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}
