// const { default: axios } = require("axios");

import axios from 'axios';

// export const BASE_URL=process.env.RECT_APP_BASE_URL;
// // console.log(BASE_URL)
// export const BASE_URL="https://sairam-once-check-841a035a1ddc.herokuapp.com"
// export const BASE_URL="https://pleasant-elk-kilt.cyclic.cloud"
export const BASE_URL = 'https://bidesh.site';
// export const BASE_URL = 'http://localhost:5003';

const ax = axios.create({
  baseURL: BASE_URL,
});

export default ax;
