import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Modal from '@mui/material/Modal';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CreateNewLangWiseTabNameModel({ languages }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [languageWiseTitle, setTitle] = React.useState('');
  const [language, setLanguage] = React.useState('');
  const token = useGetToken();
  const params = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    ax.post(
      `/admin/languagewisetab/${params.id}`,
      {
        language_wise_title:languageWiseTitle,
        language
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        // console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Create New Name
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <TextField
              style={{ width: '100%', marginBottom: 20 }}
              name="name"
                value={languageWiseTitle}
                onChange={(e)=>setTitle(e.target.value)}
              //   onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
              placeholder="Name"
              variant="outlined"
              label="Name"
              required
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Role"
                required
              >
                {/* {userType} */}
                {languages?.map((lang) => (
                  <MenuItem value={lang._id}>{lang?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" style={{ marginTop: 20, width: '100%' }} variant="contained">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
