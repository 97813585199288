import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { TextField, FormControl, InputLabel, MenuItem, Select, Button, CircularProgress } from '@mui/material';
import { setMessageError, setMessageHide, setMessageShow } from '../state/reducers/snackBarReducer';
import TextEditor from '../components/editor/TextEditor';
import useGetToken from '../utils/logic/getToken';
import ax from '../Axios';

function ContentCreateTabsContent() {
  const dispatch = useDispatch();
  const [journies, setJournies] = useState([]);
  const [language, setLanguage] = useState([]);
  const [jour, setJour] = useState('');
  const [body, setBody] = useState('');
  // const [jour, setJour] = useState('');
  const [originId, setOriginId] = useState('');
  const [loading, setLoading] = useState(false);
  const token = useGetToken();
  const { tabId } = useParams();
  const [lang, setLang] = useState('');
  const [title, setTitle] = useState('');
  const [youtubeVideoLink, setYoutubeVideoLink] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (token) {
      ax.get('/content/content/list/journey', {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          console.log(response.data);
          setJournies(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token && jour !== '') {
      ax.get(`/admin/language/${originId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setLanguage(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token, jour]);

  const navigate = useNavigate();
  // const

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (token) {

      const formData=new FormData();
      formData.append("tab",tabId);
      formData.append("journey",jour);
      formData.append("language",lang);
      formData.append("data",body.toString());
      formData.append("title",title);
      formData.append("youtube_video_link",youtubeVideoLink);
      formData.append("image",image);


      ax.post(
        `/content/content/${tabId}`,
      formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
          },
        }
      )
        .then((response) => {
          setLoading(false);
          dispatch(setMessageShow(response.data.message));
          navigate(`/content-dashboard/tabs/${tabId}`, { replace: true });
          setTimeout(() => {
            dispatch(setMessageHide());
          }, 4000);
        })
        .catch((error) => {
          // // console.log(error);
          setLoading(false);
          dispatch(setMessageError(error.response.data.error));
          setTimeout(() => {
            dispatch(setMessageHide());
          }, 4000);
        });
    }
  };

  const handleChangeJourney = (e) => {
    setJour(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Tabs content : Bideshonline </title>
      </Helmet>

      <h1>Create Content</h1>
      <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TextField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title...."
          label="Title"
          required
        />
        <FormControl required style={{ marginTop: 15 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Journey</InputLabel>
          <Select
            value={jour}
            onChange={handleChangeJourney}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Journey"
            required
          >
            {journies.map((journey) => (
              <MenuItem onClick={() => setOriginId(journey.origin._id)} key={journey._id} value={journey._id}>
                {journey.origin.name} - {journey.destination.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl required style={{ marginTop: 15 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            value={lang}
            onChange={(e) => setLang(e.target.value)}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Language"
            required
          >
            {language.map((language) => (
              <MenuItem key={language._id} value={language._id}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={youtubeVideoLink}
          onChange={(e) => setYoutubeVideoLink(e.target.value)}
          placeholder="Youtube Video"
          label="Youtube Video"
          style={{ marginTop: 20 }}
        />
        <input
          onChange={(e) => {
            if (e.target.files) {
              setImage(e.target.files[0]);
            }
          }}
          type="file"
          style={{ border: '1px solid grey', padding: 20, marginTop: 10, borderRadius: 10 }}
          required
        />

        <TextEditor setBody={setBody} body={body} />

        {loading ? (
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            <CircularProgress color="inherit" size={24} />
          </Button>
        ) : (
          <Button type="submit" style={{ marginTop: 20 }} variant="contained" color="primary">
            Submit
          </Button>
        )}
      </form>
    </>
  );
}

export default ContentCreateTabsContent;
