import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// @mui
import { Box, Card, Link, Typography, Stack, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import ax, { BASE_URL } from '../../../Axios';
import useGetToken from '../../../utils/logic/getToken';
import { setMessageError, setMessageHide, setMessageShow } from '../../../state/reducers/snackBarReducer';
import { changeActiveStatus } from '../../../state/reducers/tabReducer';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ContentTabsCard.propTypes = {
  tab: PropTypes.object,
};

export default function ContentTabsCard({ tab }) {
  const navigate = useNavigate();
  const token = useGetToken();
  const { title, image } = tab;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={title} src={`${BASE_URL}/${image}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography style={{ display: 'flex', justifyContent: 'space-between' }} variant="subtitle2" noWrap>
          <div>
            <Link onClick={() => navigate(`/content-dashboard/tabs/${tab._id}`)} color="inherit" underline="hover">
              {title}
            </Link>
          </div>
        </Typography>
      </Stack>
    </Card>
  );
}
