import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';
import { OriginPieChart } from './Charts/OriginPieChart';
import { DestinationPieChart } from './Charts/DestinationPieChart';
import { BarChart } from './Charts/BarChart';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const token = useGetToken();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      setLoading(true);
      ax.get('/admin/stats', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title> Bideshonline </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        {loading ? (
          <>
            <CircularProgress />
            <br />
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              {data && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Language" total={data.languages} icon={'ant-design:file-filled'} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Journey"
                      total={data.journey}
                      color="info"
                      icon={'ant-design:car-filled'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Country"
                      total={data.country}
                      color="warning"
                      icon={'ant-design:flag-filled'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Tabs" total={data.tabs} color="error" icon={'ant-design:bars'} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Active Users" total={data.aUsers} color="error" icon={'ant-design:user'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Inactive Users"
                      total={data.iUsers}
                      color="primary"
                      icon={'ant-design:user'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Content" total={data.tabs} color="success" icon={'ant-design:bars'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Faqs" total={data.tabs} color="primary" icon={'ant-design:book'} />
                  </Grid>
                </>
              )}
            </Grid>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 30 }}>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  margin: 10,
                  padding: 10,
                  border: '0.3px solid grey',
                  borderRadius: 20,
                }}
              >
                <h3>Origin</h3>
                <OriginPieChart />
              </div>

              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  margin: 10,
                  padding: 10,
                  border: '0.3px solid grey',
                  borderRadius: 20,
                }}
              >
                <h3>Destinations</h3>
                <DestinationPieChart />
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 30 }}>
              <BarChart />
            </div>
          </>
        )}
      </Container>
    </>
  );
}
