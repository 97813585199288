import React from 'react'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button, Modal, Box, CircularProgress, TextField } from '@mui/material';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ContentListItem = ({content,navigate}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    const token=useGetToken();

  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const handleDelete = (id) => {
    setDeleteLoading(true);
    ax.delete(`/admin/content/${id}`, {
      headers: {
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setDeleteLoading(false);
        handleClose();
        window.location.reload();
        // setContents(response.data);
      })
      .catch((error) => {
        // console.log(error);
        // setLoading(false);
        setDeleteLoading(false);
      });
  };
  return (
    <TableRow key={content._id}>

    <TableCell align="left" component="th" scope="row">
      {content?.journey?.origin?.name}
    </TableCell>

    <TableCell align="right" component="th" scope="row">
      {content?.journey?.destination?.name}
    </TableCell>

    <TableCell align="right" component="th" scope="row">
      {content?.language?.name}
    </TableCell>

    <TableCell align="right" component="th" scope="row">
      {content?.title?.length > 10 ? `${content?.title.slice(0, 10)}...` : content?.title}
    </TableCell>

    <TableCell align="right" component="th" scope="row">
      {content?.creator?.first_name} {content?.creator?.last_name}
    </TableCell>

    <TableCell align="right">{new Date(content.createdAt).toDateString()}</TableCell>
    <TableCell align="right">{new Date(content.updatedAt).toDateString()}</TableCell>

    <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
      <Button onClick={() => navigate(`/dashboard/content/edit/${content._id}`)} variant="contained">
        Edit
      </Button>
    </TableCell>

    <TableCell align="right">
      <Button onClick={handleOpen} variant="contained" color="error" disableElevation>
        Delete
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            <h2>Are you sure you want to delete this ?</h2>
          </Typography>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
            {deleteLoading ? (
              <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                <CircularProgress size={24} color="inherit" />
              </Button>
            ) : (
              <Button
                onClick={() => handleDelete(content._id)}
                color="error"
                style={{ marginRight: 10 }}
                variant="contained"
                disableElevation
              >
                Yes 
    
              </Button>
            )}

            <Button color="inherit" variant="contained" disableElevation>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </TableCell>
  </TableRow>
  )
}

export default ContentListItem