import * as React from 'react';
import Table from '@mui/material/Table';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Iconify from '../components/iconify/Iconify';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';
import CreateNewLangWiseTabNameModel from '../components/languagewisetabname/CreateNewLangWiseTabNameModel';
import EditLanugageNameModel from '../components/languagewisetabname/EditLanugageNameModel';
import { setMessageError, setMessageHide } from '../state/reducers/snackBarReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: 'flex !important',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function LanguageWiseTabs() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const token = useGetToken();
  const { id } = useParams();

  const [langWiseName, setLangWiseName] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const loc=useLocation();

  React.useEffect(() => {
    if (token && id) {
      setLoading(true);
      ax.get(`/admin/languagewisetab/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setLangWiseName(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [token, id]);

  React.useEffect(() => {
    if (token) {
      ax.get(`/admin/language`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          // setCountries(response.data);
          setLanguages(response.data);
          //   setContents(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const dispatch=useDispatch();

  const handleDelete = (id) => {
    setDeleteLoading(true);
    ax.delete(`/admin/languagewisetab/${id}`, {
      headers: {
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setDeleteLoading(false);
        handleClose();
        window.location.reload();
        // setContents(response.data);
      })
      .catch((error) => {
        // console.log(error);
        // setLoading(false);
        setDeleteLoading(false);
     
      });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard : Content </title>
      </Helmet>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
            {loc?.state?.title}
            </Typography>

            <CreateNewLangWiseTabNameModel languages={languages} />

            {/* <Button
              onClick={() => navigate(`/dashboard/content/${id}`, { replace: true })}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Content
            </Button> */}
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Language</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">{''}</TableCell>
                  <TableCell align="right">{''}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {langWiseName?.map((lang) => (
                  <TableRow key={lang._id}>
                    <TableCell align="left" component="th" scope="row">
                      {lang?.language?.name}
                    </TableCell>

                    <TableCell align="right" component="th" scope="row">
                      {lang?.language_wise_title}
                    </TableCell>

                    <TableCell align="right">{new Date(lang.createdAt).toDateString()}</TableCell>

                    <TableCell style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} align="right">
                      <EditLanugageNameModel
                        id={lang._id}
                        languages={languages}
                        lang={lang?.language?._id}
                        title={lang?.language_wise_title}
                      />
                    </TableCell>

                    <TableCell align="right">
                      <Button onClick={handleOpen} variant="contained" color="error" disableElevation>
                        Delete
                      </Button>

                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography>
                            <h2>Are you sure you want to delete this ?</h2>
                          </Typography>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
                            {deleteLoading ? (
                              <Button color="error" style={{ marginRight: 10 }} variant="contained" disableElevation>
                                <CircularProgress size={24} color="inherit" />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleDelete(lang._id)}
                                color="error"
                                style={{ marginRight: 10 }}
                                variant="contained"
                                disableElevation
                              >
                                Yes
                              </Button>
                            )}

                            <Button color="inherit" variant="contained" disableElevation>
                              Cancel
                            </Button>
                          </div>
                        </Box>
                      </Modal>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
