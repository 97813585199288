import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';

ChartJS.register(ArcElement, Tooltip, Legend);

const getRandomRGBA=(abc)=>{
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = abc;
  
    return `rgba(${r},${g},${b},${a})`;
  }

// export const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: [
//         'rgba(255, 99, 132, 0.2)',
//         'rgba(54, 162, 235, 0.2)',
//         'rgba(255, 206, 86, 0.2)',
//         'rgba(75, 192, 192, 0.2)',
//         'rgba(153, 102, 255, 0.2)',
//         'rgba(255, 159, 64, 0.2)',
//       ],
//       borderColor: [
//         'rgba(255, 99, 132, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(255, 206, 86, 1)',
//         'rgba(75, 192, 192, 1)',
//         'rgba(153, 102, 255, 1)',
//         'rgba(255, 159, 64, 1)',
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

export function DestinationPieChart() {
  const token = useGetToken();
  const [customData, setCustomData] = useState(null);
  useEffect(() => {
    if (token) {
      ax.get('/destinations-stats', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          const labels = [];
          const backgroundColor = [];
          const borderColor = [];
          const data = [];
          for (let i = 0; i < response.data.length; i += 1) {
            if (response.data[i]._id !== null) {
              labels.push(`${response.data[i]._id.name} (${response.data[i].totalUsers}) `);
              data.push(response.data[i].totalUsers);
              backgroundColor.push(getRandomRGBA(0.8));
            //   borderColor.push(getRandomRGBA(1));
            }
          }
          setCustomData({
            labels,
            datasets: [
              {
                label: '# of users have destination as',
                data,
                backgroundColor,
                // borderColor,

                borderWidth: 1,
              },
            ],
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);
  return <>{customData && <Pie data={customData} />}</>;
}
