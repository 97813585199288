import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Iconify from '../components/iconify';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Button, TextField } from '@mui/material';

import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';
import CreatePushNotificationModal from '../components/notifications/CreatePushNotificationModal';

const PushNotificationsPage = () => {
  const token = useGetToken();
  const [pushNotification, setPushNotifications] = useState([]);

  useEffect(() => {
    if (token) {
      ax.get(`/notification/push`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          console.log(response.data);
          setPushNotifications(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography
          style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          variant="h5"
          gutterBottom
        >
          Push Notifications
          {/* <TextField placeholder="Search..." variant="outlined" label="Search" /> */}
        </Typography>

        <CreatePushNotificationModal />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="right">Body</TableCell>
              <TableCell align="right">Created At</TableCell>
              <TableCell align="right">{''}</TableCell>
              <TableCell align="right">{''}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
    
            {pushNotification.map((row) => (

              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                    {row?.title}
                </TableCell>

                <TableCell align="right">{row?.body.length>30 ? `${row?.body.substring(0,20)}.....` : row?.body}</TableCell>
                <TableCell align="right">{new Date(row?.createdAt).toDateString()}</TableCell>
              </TableRow>
            ))}
        
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PushNotificationsPage;
