import { Editor } from '@tinymce/tinymce-react';
import React, { useRef,useEffect } from 'react';
import ax, { BASE_URL } from '../../Axios';
import useGetToken from '../../utils/logic/getToken';

function TextEditor({ setBody, body }) {
  const editorRef = useRef(null);
  const token = useGetToken();

  useEffect(() => {
    if (editorRef.current) {
      // Get the underlying TinyMCE editor instance
      const editor = editorRef.current.editor;

      // Set the directionality explicitly to LTR or RTL
      editor.getBody().dir = 'rtl'; // or 'rtl'

      // Refresh the editor to apply the changes
      editor.fire('ResizeEditor');
    }
  }, []);

  const handleImageUpload = async (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('image', blobInfo.blob(), blobInfo.filename());

      ax.post('/admin/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
      })
        .then((response) => {
          const imageUrl = `${BASE_URL}/${response.data}`;
          resolve(imageUrl);
        })
        .catch((error) => {
          // console.log(error);
          reject(error.response.data.error);
        });
    });

  const handleChange = (content) => {
    setBody(content);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Editor
        apiKey="gdhl8d114lsm1a597ikvc62qc5bla60gamq32g9lvv1pu6dg"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={body}
        init={{
          height: 800,
          // menubar: false,
          images_upload_handler: handleImageUpload,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'image',
            'link',
          ],
          toolbar:
            'image' +
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'image link',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={handleChange}
      />
    </div>
  );
}

export default TextEditor;
