import React,{useEffect,useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useGetToken from '../../utils/logic/getToken';
import ax from '../../Axios';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Language / Users',
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const getRandomRGBA=(abc)=>{
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = abc;
  
    return `rgba(${r},${g},${b},${a})`;
  }


// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data:[1,2,3,4,5,6,7,8],
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
  
//   ],
// };

export function BarChart() {
    const token = useGetToken();
    const [customData, setCustomData] = useState(null);
    useEffect(() => {
      if (token) {
        ax.get('/language-stats', {
          headers: {
            'x-auth-token': token,
          },
        })
          .then((response) => {
            // console.log(response.data);
            const labels = [];
            const backgroundColor = [];
            // const borderColor = [];
            const data = [];
            for (let i = 0; i < response.data.length; i += 1) {
              if (response.data[i]._id !== null) {
                labels.push(`${response.data[i]._id.name} (${response.data[i].totalUsers})`);
                data.push(response.data[i].totalUsers);
                backgroundColor.push(getRandomRGBA(0.8));
              //   borderColor.push(getRandomRGBA(1));
              }
            }
            setCustomData({
              labels,
              datasets: [
                {
                  label: '# of users have language as',
                  data,
                  backgroundColor,
                  // borderColor,
  
                  borderWidth: 1,
                },
              ],
            });
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }, [token]);
  return (<>
  {customData && 
  <Bar options={options} data={customData} />
  }
  </>)
}
