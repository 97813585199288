import * as React from "react"

const useGetName=()=>{
  const name=localStorage.getItem("name"); 
  if(name){
    return name;
  }
  return "";
}

export default useGetName