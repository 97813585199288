import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextField, CircularProgress, Button, Input, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { setMessageError, setMessageHide, setMessageShow } from '../state/reducers/snackBarReducer';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';

// ----------------------------------------------------------------------

export default function CreateNewLanguagePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const token = useGetToken();

  const [countries, setCountries] = useState([]);

  const [destination, setDestination] = useState('');

  useEffect(() => {
    ax.get('/country')
      .then((response) => {
        // // console.log(response.data);
        setCountries(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    ax.post(
      '/admin/language',
      {
        name,
        country:destination,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        setLoading(false);
        dispatch(setMessageShow(response.data.message));
        navigate('/dashboard/language', { replace: true });
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 3000);
      });
  };

  const handleChangeCountry = (e) => {
    setDestination(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title> New Language : Bideshonline </title>
      </Helmet>
      <h1>Create New Language</h1>
      <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TextField
          style={{ marginTop: 20 }}
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <FormControl style={{ marginTop: 15 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            value={destination}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Country"
            onChange={handleChangeCountry}
          >
            {countries.map((country) => (
              <MenuItem key={country._id} value={country._id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {loading ? (
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            <CircularProgress size={25} color="inherit" />
          </Button>
        ) : (
          <Button type="submit" style={{ marginTop: 20 }} variant="contained" color="primary">
            Submit
          </Button>
        )}
      </form>
    </>
  );
}
