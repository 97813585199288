import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// @mui
import { Box, Card, Link, Typography, Stack, Switch } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import ax, { BASE_URL } from '../../../Axios';
import useGetToken from '../../../utils/logic/getToken';
import { setMessageError, setMessageHide, setMessageShow } from '../../../state/reducers/snackBarReducer';
import { changeActiveStatus } from '../../../state/reducers/tabReducer';
import "../../../css/tabs_list.css"
import TabsDeleteModal from '../../../components/tabs/TabsDeleteModal';
import EditTabsModal from '../../../components/tabs/EditTabsModal';
import shorten from '../../../utils/logic/shorten';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

TabsCard.propTypes = {
  tab: PropTypes.object,
};

export default function TabsCard({ tab }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [change, setChange] = useState(tab.active);
  const token = useGetToken();

  const { title, image } = tab;
  // // console.log(process.env.REACT_APP_BASE_URL);

  const handleChangeStatus = () => {
    ax.get(`/admin/tabs/activate/${tab._id}`, {
      headers: {
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      })
      .catch((error) => {
        dispatch(setMessageError(error.response.data.error));
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 4000);
      });
    // setChange(!change);
    dispatch(changeActiveStatus(tab._id));
  };

  return (
    <Card className='TabsCard'>
      {/* <div className='TabsCard_Top'> */}
      <div style={{display:'flex',justifyContent:"flex-end"}}>
        <EditTabsModal tab={tab}/>
        <TabsDeleteModal tab={tab}/>

      </div>
      {/* </div> */}
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={title} src={`${BASE_URL}/${image}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography style={{ display: 'flex', justifyContent: 'space-between' }} variant="subtitle2" noWrap>
          <div>
            <Link onClick={() => navigate(`/dashboard/tabs/${tab._id}`)} color="inherit" underline="hover">
              {shorten(title)}
            </Link>
          </div>
          <div>
            <Link onClick={() => navigate(`/dashboard/language-wise-tabs/${tab._id}`,{state:{title}})} color="inherit" underline="hover">
              <RemoveRedEyeIcon/>
            </Link>
          </div>

          <Switch checked={tab.active} onChange={handleChangeStatus} />
        </Typography>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">

          <ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack> */}
      </Stack>
    </Card>
  );
}
