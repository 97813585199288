import React, { useEffect, useState } from 'react';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';

const CreatorCountries = () => {
  const token = useGetToken();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (token) {
      ax.get('/content/content/get-countries', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          setCountries(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8,1fr)', gap: 80 }}>
      {countries.map((country) => (
        <div
          key={country._id}
          style={{
            border: '1px solid black',
            borderRadius: 20,
            width: 180,
            height: 140,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            
          }}
        >
          <img src={`${BASE_URL}/${country.country.image}`} style={{ width: 80, height: "auto" }} alt="" />
          <div style={{  display: 'flex', justifyContent: 'center', width: '100%' }}>
            <span style={{fontSize:15,marginTop:8,overflowWrap:"break-word",textAlign:"center"}}>
            {country.country.name}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CreatorCountries;
