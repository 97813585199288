// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navContentConfig = [

  {
    title: 'Tabs',
    path: '/content-dashboard/tabs',
    icon: icon('ic_cart'),
  },
  {
    title: 'Countries',
    path: '/content-dashboard/countries',
    icon: icon('ic_cart'),
  },

];

export default navContentConfig;
