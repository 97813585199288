import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Helmet } from 'react-helmet-async';
import Paper from '@mui/material/Paper';

import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import CancelIcon from '@mui/icons-material/Cancel';
import Iconify from '../components/iconify/Iconify';
import SvgColor from '../components/svg-color';
import useGetToken from '../utils/logic/getToken';
import ax, { BASE_URL } from '../Axios';
import { setMessageError, setMessageHide, setMessageShow } from '../state/reducers/snackBarReducer';
//
// '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ContentCreatorCountry() {
  const [countries, setCountries] = useState([]);

  const { userId } = useParams();
  const token = useGetToken();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [countriesListDropdown, setCountriesListDroopdown] = useState([]);
  const [country, setCountry] = useState('');
  const dispatch = useDispatch();
  const [contentCreator, setContenteCreator] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(token){
      ax.get('/country', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // // console.log(response.data);
          setCountriesListDroopdown(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
   
  }, [token]);

  useEffect(() => {
    if (token && userId) {
      //   setLoading(true);
      ax.get(`/admin/content/content-creator/${userId}`, {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          //   setLanguage(response.data);
          //   setLanguageCache(response.data);
          setCountries(response.data.countries);
          setContenteCreator(response.data.user);
          //   setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            navigate('/', { replace: true });
          } else {
            // console.log(error);
          }
          //   setLoading(false);
        });
    }
  }, [token, userId]);

  const handleChangeLanguage = (e) => {
    setCountry(e.target.value);
  };

  const handleSubmit = () => {
    
    ax.post(
      `/admin/content/content-creator/${userId}`,
      {
        country,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        // console.log(response.data);
        //   setLanguage(response.data);
        //   setLanguageCache(response.data);
        setLoading(false);
        handleClose();
        window.location.reload(true);
        dispatch(setMessageShow(response.data.message));
        // navigate("/dashboard/countries",{replace:true});
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 3000);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        dispatch(setMessageError(error.response.data.error));
        // navigate("/dashboard/countries",{replace:true});
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 3000);
      });
  };

  const handleDelete = (_id) => {
    const updatedCountries = countries.filter((country) => country._id !== _id);
    setCountries(updatedCountries);
    ax.delete(`/admin/content/content-creator/${_id}`, {
      headers: {
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        //   setLanguage(response.data);
        //   setLanguageCache(response.data);
        //   setLoading(false);
        dispatch(setMessageShow(response.data.message));
        // navigate("/dashboard/countries",{replace:true});
        setTimeout(() => {
          dispatch(setMessageHide());
        }, 3000);
      })
      .catch((error) => {
        // console.log(error);
        //   setLoading(false);
      });
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {contentCreator && (
          <Typography
            style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            variant="h4"
            gutterBottom
          >
            {contentCreator.first_name} {contentCreator.last_name}- Countries
          </Typography>
        )}
        <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
          Add Country
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Country"
                onChange={handleChangeLanguage}
              >
                {countriesListDropdown?.map((country) => (
                  <MenuItem key={`o_${country._id}`} value={country._id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={handleSubmit}
              style={{ width: '100%', marginTop: 20 }}
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </Stack>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gridGap: 30 }}>
        {countries.map((country) => (
          <div key={country._id}>
            <div style={{ position: 'relative', left: '70%', top: '15%' }}>
              <CancelIcon onClick={() => handleDelete(country._id)} style={{ color: 'blue' }} />
            </div>
            <div
              key={country._id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img alt="" style={{ width: 100 }} src={`${BASE_URL}/${country.country.image}`} />
              <span style={{ fontWeight: 800 }}>{country.country.name}</span>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default ContentCreatorCountry;
