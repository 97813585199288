import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextField, CircularProgress, Button, Input } from '@mui/material';
import { setMessageError, setMessageHide, setMessageShow } from  '../state/reducers/snackBarReducer';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';

// ----------------------------------------------------------------------



export default function CreateNewCountryPage() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useGetToken();

  const [name, setName] = useState('');
  const [image, setImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log("creatingggg....");
    const bodyFormData = new FormData();
    bodyFormData.append('name', name);
    bodyFormData.append('image', image);

    ax.post('/admin/journey/country', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        setLoading(false);
   
        dispatch(setMessageShow(response.data.message));
        setTimeout(() => {
        dispatch(setMessageHide());
        }, 3000);
      })
      .catch((error) => {
        // // console.log(error);
        dispatch(setMessageError(error.response.data.error));
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title> New Tabs : Bideshonline </title>
      </Helmet>
      <h1>Create New Country</h1>
      <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TextField
          style={{ marginTop: 20 }}
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          accept="image/*"
          required
          type="file"
          style={{ marginTop: 10, border: '1px solid black', padding: 15, borderRadius: 5 }}
          onChange={(e) => setImage(e.target.files[0])}
        />
        {loading ? (
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            <CircularProgress size={25} color="inherit" />
          </Button>
        ) : (
          <Button type="submit" style={{ marginTop: 20 }} variant="contained" color="primary">
            Submit
          </Button>
        )}
      </form>
    </>
  );
}
