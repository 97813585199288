import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import ax from '../../Axios';
import useGetToken from '../../utils/logic/getToken';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CountriesModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const token = useGetToken();
  const [countries,setCountries]=React.useState([]);

  React.useEffect(() => {
    if(token)
    {
        ax.get('/content/content/get-countries', {
      headers: {
        'x-auth-token': token,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setCountries(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
    }
  }, [token]);

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Countires
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1>Countries</h1>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {countries?.map((value) => (
              <ListItem
                key={value}
                disableGutters
              >
                <ListItemText primary={`${value?.country?.name}`} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </div>
  );
}
