import React, { useEffect, useState } from 'react';
import '../css/info.css';
import { TextField, Button, Avatar, CircularProgress } from '@mui/material';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';

function InfoPage() {
  const token = useGetToken();
  const [fulladdress, setFullAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [name, setName] = useState('');
  const [about, setAbout] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      ax.get('/info', {
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          // console.log(response.data);
          setEmail(response.data.email);
          setPhonenumber(response.data.contact_number);
          setAbout(response.data.about);
          setFullAddress(response.data.full_address);
          setName(response.data.name);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    ax.post(
      `/info`,
      {
        name,
        full_address: fulladdress,
        contact_number: phonenumber,
        email,
        about,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    )
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="Info_Head">
        <Avatar alt="Remy Sharp" src="https://pixy.org/src/21/219269.jpg" />
        <h1 style={{ marginLeft: 10 }}>{name}</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="Info_Body">
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name..."
            fullWidth
            label="Name"
            id="name"
            required
          />
        </div>
        <div className="Info_Body">
          <TextField
            value={fulladdress}
            onChange={(e) => setFullAddress(e.target.value)}
            placeholder="Address..."
            fullWidth
            label="Address"
            id="address"
            required
          />
        </div>

        <div className="Info_Body">
          <input
            placeholder="Contact..."
            type="file"
            style={{ border: '1px solid black', borderRadius: 5, width: '100%', margin: 10, padding: 15 }}
          />
        </div>

        <div className="Info_Body">
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email..."
            fullWidth
            label="Email"
            id="email"
            required
          />
          <TextField
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
            placeholder="Number..."
            fullWidth
            label="Number"
            id="number"
            required
          />
        </div>
        <div className="Info_Body">
          <textarea
            required
            style={{ padding: 10, width: '100%', margin: 10, height: 100 }}
            placeholder="Aboutus...."
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>

        <div className="Info_Body_Low">
          {loading ? (
            <Button style={{ width: '100%', height: 50, margin: 10 }} variant="contained">
              <CircularProgress size={24} color="inherit" />
            </Button>
          ) : (
            <Button type="submit" style={{ width: '100%', height: 50, margin: 10 }} variant="contained">
              Submit
            </Button>
          )}
        </div>
      </form>
    </>
  );
}

export default InfoPage;
