
const shorten=(text)=>{
    if(text.length>20)
    {
        return `${text.slice(0,10)}...`;
    }
    return text;

}

export default shorten;