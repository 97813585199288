import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { TextField, Container, Stack, Typography, Button } from '@mui/material';
import Iconify from '../components/iconify';
// components
// mock
import PRODUCTS from '../_mock/products';
import ax from '../Axios';
import useGetToken from '../utils/logic/getToken';
import { searchTabs, startTabsFetch, startTabsFetchError, startTabsFetchSuccess } from '../state/reducers/tabReducer';
import TabsList from '../sections/@dashboard/tabs/TabsList';

// ----------------------------------------------------------------------

export default function TabsPage() {
  const dispatch = useDispatch();
  const { loading, tabs, error,emptyList } = useSelector((state) => state.tabReducer);

  const [openFilter, setOpenFilter] = useState(false);
  const token = useGetToken();

  useEffect(() => {
    if (token) {
      dispatch(startTabsFetch());
      ax.get('/admin/tabs', {
        headers: {
          'x-auth-token': token || '',
        },
      })
        .then((response) => {
          // // console.log(response.data);
          dispatch(startTabsFetchSuccess(response.data));
        })
        .catch((error) => {
          // // console.log(error);
          dispatch(startTabsFetchError(error.response.data));
        });
    }
  }, [token]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);

  };

  const handleSearchTab=(e)=>{
    dispatch(searchTabs(e.target.value));
  }

  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <h1>Loading....</h1>
      ) : (
        <>
          <Helmet>
            <title> Dashboard: Tabs </title>
          </Helmet>

          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography
                style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                variant="h4"
                gutterBottom
              >
                Tabs
                <TextField onChange={handleSearchTab} placeholder="Search..." variant="outlined" label="Search" />
              </Typography>
              <Button
                onClick={() => navigate('/dashboard/newtabs')}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Tab
              </Button>
            </Stack>

            {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
              <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                <ProductFilterSidebar
                  openFilter={openFilter}
                  onOpenFilter={handleOpenFilter}
                  onCloseFilter={handleCloseFilter}
                />
                <ProductSort />
              </Stack>
            </Stack> */}
          {emptyList && 
          <h1>{emptyList}</h1>
           }
            <TabsList tabs={tabs} />
            {/* <ProductCartWidget /> */}
          </Container>
        </>
      )}
    </>
  );
}
